import Swiper from 'swiper'
import { Pagination } from 'swiper/modules'

class VideoCarousel extends HTMLElement {
  constructor() {
    super()

    this.carousel = this.querySelector('[data-carousel]')
    this.initSwiper()
  }

  async initSwiper() {
    const swiper = new Swiper(this.carousel, {
      modules: [Pagination],
      slidesPerView: 1.297,
      spaceBetween: 16,
      breakpoints: {
        460: {
          slidesPerView: 2.4,
          spaceBetween: 18
        },
        744: {
          slidesPerView: 3.2,
          spaceBetween: 18
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 18
        }
      },
      pagination: {
        el: ".swiper-pagination",
        type: "progressbar",
      },
    })
  }
}

window.customElements.define('video-carousel', VideoCarousel)
